import {createApp} from 'vue'
import App from './application/App.vue'
import router from './application/router'
import './application/css/tailwind.css'
import 'swiper/css';
import i18nConfig from '@/i18n-config'
import piniaPluginPersistedstate from 'pinia-plugin-persistedstate';
import {createPinia} from 'pinia'
import {cropper} from 'vue-picture-cropper'
import GoogleSignInPlugin from "vue3-google-signin"
import '@vuepic/vue-datepicker/dist/main.css'
import 'floating-vue/dist/style.css'
import 'aos/dist/aos.css'
import FloatingVue from 'floating-vue'
import {createMetaManager} from "vue-meta";
import {VueReCaptcha} from 'vue-recaptcha-v3'

const app = createApp(App)
const pinia = createPinia();
pinia.use(piniaPluginPersistedstate);

app.use(GoogleSignInPlugin, {
    clientId: process.env.VUE_APP_GOOLE_CLIENT_ID,
});
app.use(cropper)
app.use(pinia)
app.use(createMetaManager())
app.use(FloatingVue)
app.use(i18nConfig.i18n)
app.use(router)
app.use(VueReCaptcha, {
    siteKey: process.env.VUE_APP_RECAPTCHA_KEY as string,
    loaderOptions: {
        autoHideBadge: true
    }
});

app.mount('#app')
