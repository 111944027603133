import { createI18n } from 'vue-i18n'
import { storage } from '@/infrastructure/storage/storage'

let locale: any = 'es'

//TODO cambiar a un selector en el front
storage.set('currentLocale', locale)

if (storage.get('currentLocale') !== '' && storage.get('currentLocale') !== null) {
  locale = storage.get('currentLocale')
}
const i18n = createI18n<false>({
  legacy: false,
  locale: locale,
  messages: {
    es: require('@/application/translations/es.json'),
    en: require('@/application/translations/en.json')
  }
})
const instance = i18n.global

export default { i18n, instance }