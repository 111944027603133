import { defineStore } from 'pinia'
import Club from '@/domain/models/Club'
import { clubRepository } from '@/infrastructure/repositories/club.repository'
import { IClubState } from '@/domain/interfaces/States/IClubState'
import Pagination from '@/domain/shared/Pagination'

export const useClubStore = defineStore('useClubStore', {
  state: (): IClubState => ({
    club: null,
    clubs: [],
    pagination: new Pagination(),
    context: {
      filter: {
        all: ''
      },
      sortBy: 'name',
      sortDesc: false,
      currentPage: 1,
      perPage: 5
    }
  }),
  actions: {
    async setClub(club: Club) {
      this.club = club
    },
    async getByContext(context = {}) {
      const mergedContext = { ...this.context, ...context }
      const response = await clubRepository.getByContext(mergedContext)
      if (response.data) {
        this.clubs = response.data
        this.pagination = response.pagination
        return response.data
      }
    },
    async getByContextOwned(context = {}) {
      const mergedContext = { ...this.context, ...context }
      const response = await clubRepository.getByContextOwned(mergedContext)
      if (response.data) {
        this.clubs = response.data
        this.pagination = response.pagination
        return response.data
      }
    }
  },
  getters: {
    getContext(state) {
      return state.context
    },
    getPagination(state) {
      return state.pagination
    },
    getClub(state) {
      return state.club as Club
    },
    getClubs(state) {
      return state.clubs
    }
  }
})