export interface UserDTO {
  readonly id: number
  name: string
  username: string
  full_name: string
  surname: string
  email?: string
  phone?: string
  phone_prefix?: string
  password?: string
  password_confirmation?: string
  type?: string
  nif?: string
  avatar?: string
  prefered_language?: string
  terms_of_use_and_privacy?: boolean
  comes_from_social?: boolean
  promotions?: boolean
  stripe_active?: boolean
  stripe_account_id?: string
}

export class UserMapper {
  public static toDTO(user: any): UserDTO {
    return {
      id: user.id,
      username: user.username,
      name: user.name,
      full_name: user.full_name,
      surname: user.surname,
      email: user.email,
      phone: user.phone,
      phone_prefix: user.phone_prefix,
      password: user.password,
      password_confirmation: user.password_confirmation,
      type: user.type,
      nif: user.nif,
      avatar: user.avatar,
      prefered_language: user.prefered_language,
      terms_of_use_and_privacy: user.terms_of_use_and_privacy,
      comes_from_social: user.comes_from_social,
      promotions: user.promotions,
      stripe_active: user.stripe_active,
      stripe_account_id: user.stripe_account_id,
    }
  }
}
