import {useClubStore} from "@/application/store/useClubStore";
import {clubRepository} from "@/infrastructure/repositories/club.repository";
import Club from "@/domain/models/Club";
import {RouteLocationNormalized, NavigationGuardNext} from 'vue-router';

export async function setClubFromRouteParamsGuard(to: RouteLocationNormalized, from: RouteLocationNormalized, next: NavigationGuardNext) {
    const clubId = to.params.id as string | number; // Asumiendo que id siempre es un string, ajusta según sea necesario
    const clubStore = useClubStore();

    try {
        const club = await clubRepository.show(clubId);
        if (club instanceof Club) {
            await clubStore.setClub(club);
            next()
            return // Evita llamar a next('/') después de este bloque
        }
    } catch (e) {
        next('/')
        //TODO Controlar error
        console.error(e);
    }

    next('/');
}
