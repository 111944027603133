import {defineStore} from 'pinia'

export const useRootStore = defineStore('useRootStore', {
    state: (): any => ({
        currentRoute: '/'
    }),
    actions: {
        setCurrentRoute(value: string) {
            this.currentRoute = value
        }
    },
    getters: {
        getCurrentRoute(state) {
            return state.currentRoute
        }
    }
})