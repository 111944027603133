import { defineStore } from 'pinia'
import User from '@/domain/models/User'
import { authService } from '@/domain/services/auth.service'
import {IUserState} from "@/domain/interfaces/States/IUserState";

export const useUserStore = defineStore('useUserStore',{
  state: (): IUserState => ({
    isPlayerLogged: false,
    isClubLogged: false,
    openLoginModal: false,
    currentUser: authService.getCurrentUser(),
    modelRules: JSON.parse(JSON.stringify(User.rules))
  }),
  actions: {
    async getUserAuth() {
      const user = await authService.getUserAuth()
      if (user?.id) {
        authService.setCurrentUser(user)
      }

      return user
    },
    setLoginModal(value: boolean) {
      this.openLoginModal = value
    },
    setIsClubLogged(value: boolean) {
      this.isClubLogged = value
    },
    setIsPlayerLogged(value: boolean) {
      this.isPlayerLogged = value
    },
    setCurrentUser(user: User){
      this.currentUser = user
    }
  },
  getters: {
  }
})