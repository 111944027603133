import { apiService } from '@/domain/services/api.service'
import { UserMapper } from '../http/dto/UserDTO'
import Errors from '@/domain/shared/Errors'

export const authRepository = {
  login: async (email: string, password: string): Promise<IAuthResponse> => {
    const response = await apiService.publicPost('users/login', { email: email, password: password })
    const accessToken = response.content.body?.token ?? null
    const currentUser = response.content.body?.user ?? null

    const { errors, message } = response.content
    return {
      error: {
        check: !response.ok,
        status: response.status,
        errors,
        message
      },
      access_token: accessToken,
      currentUser: currentUser
    }
  },
  logout: async () => {
    return await apiService.privateGet('users/logout')
  },
  loginWithSocial: async (token: string, provider: string): Promise<IAuthResponse> => {
    const response = await apiService.publicPost('users/login/socialite', { token: token, provider: provider })
    const accessToken = response.content.body?.token ?? null
    const currentUser = response.content.body?.user ?? null

    const { errors, message } = response.content
    return {
      error: {
        check: !response.ok,
        status: response.status,
        errors,
        message
      },
      access_token: accessToken,
      currentUser: currentUser
    }
  },
  register: async (errors: Errors, data: any) => {
    const response = await apiService.publicPost('users/register', UserMapper.toDTO(data), {})

    if (!response.ok && response.content.errors) {
      errors.record(response.content.errors)
    }

    return response
  },
  registerWithSocial: async (token: string, phone: string, phonePrefix: string, provider: string, username: string, terms_of_use_and_privacy: boolean, promotions:boolean) => {
    return await apiService.publicPost('users/socialite', {
      token: token,
      provider: provider,
      phone: phone,
      phone_prefix: phonePrefix,
      username: username,
      terms_of_use_and_privacy: terms_of_use_and_privacy,
      promotions: promotions,
    })
  },
  changePassword: async (errors: Errors, data: any) => {
    const response = await apiService.privatePut('users/update-password', data)
    if (!response.ok && response.content.errors) {
      errors.record(response.content.errors)
    }

    return response
  },
  recaptcha: async (token: string) => {
    const response = await apiService.publicPost('recaptcha', {token: token}, {})
    return response.content.body
  },
}
