import { RouteRecordRaw } from 'vue-router'
import i18nConfig from '@/i18n-config'
import { setClubFromRouteParamsGuard } from "@/application/router/guards/setClubFromRouteParamsGuard";
import { checkBookingStorage } from "@/application/router/guards/checkBookingStorage";

export const routes: Array<RouteRecordRaw> = [
    {
        path: '/',
        name: 'MainLayout',
        component: () => import('../layouts/MainLayout.vue'),
        meta: {
            middleware: 'translation'
        },
        children: [
            {
                path: '',
                name: 'Index',
                component: () => import('@/application/views/Index.vue')
            },
            {
                path: i18nConfig.instance.t('routes.club.index'),
                name: 'ClubIndex',
                component: () => import('@/application/views/ClubPage.vue'),
            },
            {
                path: i18nConfig.instance.t('routes.search'),
                name: 'Search',
                component: () => import('@/application/views/Search.vue'),
            },
            {
                path: i18nConfig.instance.t('routes.aboutUs'),
                name: 'AboutUs',
                component: () => import('@/application/views/pages/AboutUs.vue'),
            },
            {
                path: i18nConfig.instance.t('routes.termsAndConditions'),
                name: 'Terminos y condiciones',
                component: () => import('@/application/views/pages/TermsAndConditions.vue'),
            },
            {
                path: i18nConfig.instance.t('routes.cookiesPolicy'),
                name: 'Politica de cookies',
                component: () => import('@/application/views/pages/CookiesPolicy.vue'),
            },
            {
                path: i18nConfig.instance.t('routes.privacyPolicy'),
                name: 'Politica de Privacidad',
                component: () => import('@/application/views/pages/PrivacyPolicy.vue'),
            },
            {
                path: i18nConfig.instance.t('routes.userGuides'),
                name: 'Guía de Usuario',
                component: () => import('@/application/views/pages/UserGuides.vue'),
            },
            {
                path: i18nConfig.instance.t('routes.club.detail'),
                name: 'Club Detail',
                component: () => import('@/application/views/ClubDetail.vue'),
                props: true,
                beforeEnter: setClubFromRouteParamsGuard
            },
            {
                path: i18nConfig.instance.t('routes.user.account.profile'),
                name: 'Profile',
                component: () => import('@/application/views/user/account/Profile.vue'),
                meta: {
                    middleware: 'auth'
                },
                children: [
                    {
                        path: i18nConfig.instance.t('routes.user.account.profile'),
                        name: 'EditProfile',
                        component: () => import('@/application/views/user/account/profile/EditProfile.vue')
                    },
                    {
                        path: i18nConfig.instance.t('routes.user.account.bookings'),
                        name: 'UserBookings',
                        component: () => import('@/application/views/user/account/bookings/UserBookings.vue')
                    }
                ]
            },
            {
                name: 'ResetPassword',
                path: i18nConfig.instance.t('routes.resetPasswordToken'),
                component: () => import('@/application/views/user/auth/ResetPassword.vue'),
                props: true
            },
            {
                path: '/account-verified',
                name: 'AccountVerified',
                component: () => import('@/application/views/pages/AccountVerified.vue')
            },
            {
                path: i18nConfig.instance.t('routes.thankYou'),
                name: 'ThankYou',
                props: true,
                component: () => import('@/application/views/pages/ContactedPage.vue')
            },
            {
                path: i18nConfig.instance.t('routes.contact'),
                name: 'ContactUs',
                component: () => import('@/application/views/ContactUs.vue')
            },
            {
                path: i18nConfig.instance.t('routes.checkoutCreated'),
                name: 'CheckoutCreated',
                component: () => import('@/application/views/ReservationCreated.vue')
            },
            {
                path: '/:catchAll(.*)',
                component: () => import('@/application/views/pages/404Page.vue'),
                name: 'NotFound'
            }
        ]
    },
    {
        path: '/',
        name: 'CleanLayout',
        component: () => import('../layouts/CleanLayout.vue'),
        meta: {
            middleware: 'translation'
        },
        children: [
            {
                path: i18nConfig.instance.t('routes.checkout'),
                name: 'Checkout',
                meta: {
                    middleware: 'auth'
                },
                component: () => import('@/application/views/bookings/Checkout.vue'),
                beforeEnter: checkBookingStorage
            }
        ]
    },
]