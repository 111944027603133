import {UserDTO} from '@/infrastructure/http/dto/UserDTO'

export default class User {
    id?: number
    full_name?: string
    username?: string
    name?: string
    surname?: string
    email?: string
    phone?: string
    phone_prefix?: string
    password?: string
    password_confirmation?: string
    type?: string
    nif?: string
    avatar?: string
    prefered_language?: string
    terms_of_use_and_privacy?: boolean
    comes_from_social?: boolean
    promotions?: boolean
    stripe_active?: boolean
    stripe_account_id?: string

    constructor(rawData: UserDTO) {
        const fields: Array<keyof UserDTO> = Object.keys(rawData) as Array<keyof UserDTO>
        fields.forEach((value) => {
            this[value] = rawData[value] as any
        })
    }

    static rules = {
        username: 'required|isUsername',
        name: 'required',
        surname: 'required',
        email: 'required|isEmail',
        password: 'required',
        password_confirmation: 'required',
        terms_of_use_and_privacy: 'required'
    }

    static PLAYER_TYPE: string = 'player'
    static CLUB_TYPE: string = 'club'

    static GOOGLE_LOGIN_TYPE: string = 'google'
    static NATIVE_LOGIN_TYPE: string = 'native'

    static getEmptyFormFields(): Record<string, any> {
        return {
            id: '',
            username: '',
            name: '',
            surname: '',
            email: '',
            phone: '',
            phone_prefix: '',
            password: '',
            password_confirmation: '',
            type: '',
            nif: '',
            avatar: '',
            prefered_language: '',
            terms_of_use_and_privacy: false,
            promotions: false,
            comes_from_social: false,
        }
    }
}
