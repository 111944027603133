export interface ClubDTO {
  readonly id: number
  country_id: number
  user_id: number
  name: string
  address: string
  postcode?: string
  phone?: string
  phone_prefix?: string
  logo?: string
  active?: boolean
  description?: string
  instagram?: string
  facebook?: string
  twitter?: string
  region_id?: number
  city_id?: number
  fee_direction?: boolean
  country_and_region?: string
  media?: []
  courts?: []
  sports?: []
  schedules?: []
  country?: []
  city?: []
  region?: []

}

export class ClubMapper {
  public static toDTO(club: any): ClubDTO {
    return {
      id: club.id,
      country_id: club.country_id,
      user_id: club.user_id,
      name: club.name,
      address: club.address,
      postcode: club.postcode,
      phone: club.phone,
      phone_prefix: club.phone_prefix,
      logo: club.logo,
      active: club.active,
      description: club.description,
      instagram: club.instagram,
      twitter: club.twitter,
      facebook: club.facebook,
      region_id: club.region_id,
      city_id: club.city_id,
      fee_direction: club.fee_direction,
      country_and_region: club.country_and_region,
      media: club.media,
      courts: club.courts,
      sports: club.sports,
      schedules: club.schedules,
      country: club.country,
      city: club.city,
      region: club.region
    }
  }
}
