import {storage} from '@/infrastructure/storage/storage'
import router from '@/application/router'
import {format} from "date-fns";
import {es} from 'date-fns/locale';

export default {
    scrollTop: () => {
        window.scrollTo({top: 0, behavior: "smooth"});
    },
    delay: (ms: any) => new Promise(r => setTimeout(r, ms)),
    redirectWithLocale: (route: string) => {
        const locale = storage.get('currentLocale')
        const redirectWithLocale = '/' + locale + '/' + route
        router.push(redirectWithLocale)
    },
    redirect: (route: string) => {
        router.push(route)
    },
    closeHeader: () => {
        const popoverButtonRef = document.getElementById('popoverButtonRef')
        popoverButtonRef?.click()
    },
    capitalize(string: string) {
        return string.charAt(0).toUpperCase() + string.slice(1);
    },
    formatDateToHumanReadable(dateString: string) {
        const [year, month] = dateString.split('-').map(Number);
        return format(new Date(year, month - 1, 1), 'MMMM yyyy', {locale: es});
    },
    formattedDate(string: string, formatStr: string = 'dd/MM/yyyy HH:mm') {
        return format(new Date(string), formatStr);
    },
    getFirstDayOfWeek(year: number, month: number) {
        const firstDayOfMonth = new Date(year, month, 1);
        return firstDayOfMonth.getDay();
    },
    convertMinutesToHoursAndMinutes(minutes: number) {
        if (minutes < 60) {
            return `${minutes} minuto${minutes === 1 ? '' : 's'}`;
        } else {
            const hours = Math.floor(minutes / 60);
            const minutesRemaining = minutes % 60;
            const hourString = `${hours} hora${hours === 1 ? '' : 's'}`;
            const minuteString = minutesRemaining > 0 ? ` y ${minutesRemaining} minuto${minutesRemaining === 1 ? '' : 's'}` : '';

            return hourString + minuteString;
        }
    }

};