import {createRouter, createWebHistory} from 'vue-router'
import {routes} from './routes'
import {registerMiddlewares} from '@/application/router/middleware'

const router = createRouter({
    history: createWebHistory(),
    routes,
    scrollBehavior(to, from, savedPosition) {
        // always scroll to top
        return {top: 0}
    },
})

registerMiddlewares(router)
export default router