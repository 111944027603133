import {ClubDTO} from '@/infrastructure/http/dto/ClubDTO'
import {ITableRow} from '@/domain/interfaces/ITableRow'

export default class Club {
    id?: number
    country_id?: number
    user_id?: number
    name?: string
    address?: string
    postcode?: string
    phone?: string
    phone_prefix?: string
    logo?: string
    active?: boolean
    description?: string
    instagram?: string
    facebook?: string
    twitter?: string
    region_id?: number
    city_id?: number
    fee_direction?: boolean
    courts?: []
    country_and_region?: string
    media?: []
    sports?: []
    schedules?: []
    country?: []
    city?: []
    region?: []

    constructor(rawData: ClubDTO) {
        const fields: Array<keyof ClubDTO> = Object.keys(rawData) as Array<keyof ClubDTO>
        fields.forEach((value) => {
            this[value] = rawData[value] as any
        })
    }

    static rules = {
        country_id: 'required',
        name: 'required|min:5',
        address: 'required',
        postcode: 'required',
        phone: 'required',
        phone_prefix: 'required',
        description: 'required',
        region_id: 'required',
        city_id: 'required'
    }

    // Estructura de la tabla
    static getTableFields(): ITableRow[] {
        return [
            {key: 'name', label: 'Nombre', watchInMobile: true},
            {key: 'address', label: 'Direccion', watchInMobile: false},
            {key: 'country_and_region', label: 'País', watchInMobile: true, filtered: false},
        ]
    }

    static getEmptyFormFields(): Record<string, any> {
        return {
            id: '',
            country_id: '',
            user_id: '',
            name: '',
            address: '',
            postcode: '',
            phone: '',
            phone_prefix: '',
            logo: '',
            description: '',
            region_id: '',
            city_id: '',
            facebook: '',
            twitter: '',
            instagram: '',
            media: [],
            country: [],
            city: [],
            region: [],
        }
    }
}
