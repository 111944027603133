import {apiService} from '@/domain/services/api.service'
import Errors from '@/domain/shared/Errors'
import {ClubMapper} from '@/infrastructure/http/dto/ClubDTO'
import Club from '@/domain/models/Club'
import Pagination from '@/domain/shared/Pagination'
import {ITableContext} from '@/domain/interfaces/ITableContext'

export const clubRepository = {
    store: async (errors: Errors, data: any) => {
        const response = await apiService.privatePost('clubs', ClubMapper.toDTO(data), {})
        if (!response.ok && response.content.errors && response.status == 422) {
            errors.record(response.content.errors)
        }
        return response
    },
    update: async (errors: Errors, data: any) => {
        const response = await apiService.privatePut(`clubs/${data.id}`, ClubMapper.toDTO(data), {})
        if (!response.ok && response.content.errors && response.status == 422) {
            errors.record(response.content.errors)
        }
        return response
    },
    search: async (filters: any) => {
        const response = await apiService.publicPost('clubs/search', filters)
        if (response.ok && response.content.body) {
            const data = response.content.body.clubs.data.map((item: any) => {
                return new Club(ClubMapper.toDTO(item))
            })
            return {data, pagination: new Pagination(response.content.body.clubs)}
        }

        return response.content
    },
    show: async (id: number | string) => {
        const response = await apiService.publicGet('clubs/' + id)
        if (response.ok && response.content.body) {
            return new Club(ClubMapper.toDTO(response.content.body.club))
        }

        return response.content
    },
    availability: async (id: number | string, sportId: number) => {
        const response = await apiService.publicGet(`clubs/${id}/availability?sport_id=${sportId}`)
        if (response.ok && response.content.body) {
            return response.content.body
        }
        return response.content
    },
    availableHours: async (id: number | string, day: string, sportId: number) => {
        const response = await apiService.publicPost(`clubs/${id}/availability/hours`, {day: day, sport_id: sportId})
        if (response.ok && response.content.body) {
            return response.content.body.available_times
        }
        return response.content
    },
    availableRanges: async (id: number | string, date: string, sportId: number) => {
        const response = await apiService.publicPost(`clubs/${id}/availability/hours/ranges`, {date: date, sport_id: sportId})
        if (response.ok && response.content.body) {
            return response.content.body.available_ranges
        }
        return response.content
    },
    availableCourtsForBooking: async (id: number | string, day: string, range: number, hour: number, sportId: number) => {
        const response = await apiService.publicPost(`clubs/${id}/availability/courts`, {
            day: day,
            range: range,
            hour: hour,
            sport_id: sportId
        })

        if (response.ok && response.content.body) {
            return response.content.body.available_courts
        }

        return response.content
    },
    getByContext: async (context: ITableContext) => {
        const response = await apiService.publicGet(`clubs?context=${JSON.stringify(context)}`)
        if (response.ok && response.content.body) {
            const data = response.content.body.clubs.data.map((item: any) => {
                return new Club(ClubMapper.toDTO(item))
            })
            return {data, pagination: new Pagination(response.content.body.clubs)}
        }

        return response.content
    },
    getByContextOwned: async (context: ITableContext) => {
        const response = await apiService.privateGet(`clubs/owned?context=${JSON.stringify(context)}`)

        if (response.ok && response.content.body) {
            const data = response.content.body.clubs.data.map((item: any) => {
                return new Club(ClubMapper.toDTO(item))
            })
            return {data, pagination: new Pagination(response.content.body.clubs)}
        }

        return response.content
    },
    addMedia: async (errors: Errors, id: any, formData: FormData) => {
        const response = await apiService.privatePostWithFile(`clubs/${id}/media`, formData, {})
        if (!response.ok && response.content.errors && response.status == 422) {
            errors.record(response.content.errors)
        }
        return response
    },
    deleteMedia: async (errors: Errors, clubId: number, mediaId: number) => {
        const response = await apiService.destroy(`clubs/${clubId}/media/${mediaId}`, {})
        if (!response.ok && response.content.errors && response.status == 422) {
            errors.record(response.content.errors)
        }
        return response
    },
}