const default_headers = {
  'Content-Type': 'application/json',
  'Access-Control-Allow-Origin': '*',
  Accept: 'application/json'
}

const default_file_headers = {
  Accept: 'application/json'
}

export interface HttpResponse {
  ok: boolean
  status: number
  content: Record<string, any>
}

const get = async (url: string, headers: Record<string, any> = {}): Promise<HttpResponse> => {
  const response = await fetch(url, {
    method: 'GET',
    headers: {
      ...default_headers,
      ...headers
    }
  })

  return {
    ok: response.ok,
    status: response.status,
    content: await response.json()
  } as HttpResponse
}

const emptyGet = async (url: string, headers: Record<string, any> = {}): Promise<HttpResponse> => {
  const response = await fetch(url, {
    method: 'GET',
    headers: {
      ...default_headers,
      ...headers
    }
  })

  return {
    ok: response.ok,
    status: response.status
  } as HttpResponse
}

const post = async (url: string, body: any, headers: Record<string, any> = {}): Promise<HttpResponse> => {
  const response = await fetch(url, {
    method: 'POST',
    body: JSON.stringify(body),
    headers: {
      ...default_headers,
      ...headers
    }
  })
  return {
    ok: response.ok,
    status: response.status,
    content: await response.json()
  } as HttpResponse
}

const put = async (url: string, body: any, headers: Record<string, any> = {}): Promise<HttpResponse> => {
  const response = await fetch(url, {
    method: 'PUT',
    body: JSON.stringify(body),
    headers: {
      ...default_headers,
      ...headers
    }
  })

  return {
    ok: response.ok,
    status: response.status,
    content: await response.json()
  } as HttpResponse
}

const postWithFile = async (url: string, body: FormData, headers: Record<string, any> = {}): Promise<HttpResponse> => {
  const response = await fetch(url, {
    method: 'POST',
    body: body,
    headers: {
      ...default_file_headers,
      ...headers
    }
  })
  return {
    ok: response.ok,
    status: response.status,
    content: await response.json()
  } as HttpResponse
}

const putWithFile = async (url: string, body: FormData, headers: Record<string, any> = {}): Promise<HttpResponse> => {
  const response = await fetch(url, {
    method: 'PUT',
    body: body,
    headers: {
      ...default_file_headers,
      ...headers
    }
  })

  return {
    ok: response.ok,
    status: response.status,
    content: await response.json()
  } as HttpResponse
}

const _delete = async (url: string, body?: any, headers: Record<string, any> = {}): Promise<HttpResponse> => {
  const response = await fetch(url, {
    method: 'DELETE',
    body: JSON.stringify(body),
    headers: {
      ...default_headers,
      ...headers
    }
  })

  return {
    ok: response.ok,
    status: response.status,
    content: await response.json()
  } as HttpResponse
}

const download = async (url: string, headers: Record<string, any> = {}): Promise<HttpResponse> => {
  const response = await fetch(url, {
    method: 'GET',
    headers: {
      ...default_headers,
      ...headers
    }
  })

  return {
    ok: response.ok,
    status: response.status,
    content: await response
  } as HttpResponse
}

export const http = {
  get,
  emptyGet,
  post,
  postWithFile,
  put,
  putWithFile,
  delete: _delete,
  download
}
