import Helper from "@/domain/shared/Helper";
import {NavigationGuardNext, RouteLocationNormalized} from "vue-router";
import {storage} from "@/infrastructure/storage/storage";

export function checkBookingStorage(to: RouteLocationNormalized, from: RouteLocationNormalized, next: NavigationGuardNext) {
    const data = storage.get('booking')
    if (data) {
        next();
    } else {
        Helper.redirect('/');
    }
}
