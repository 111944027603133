<template>
  <metainfo>
    <template v-slot:title="{ content }">{{ content ? `${content} - Playsportec` : `Playsportec` }}</template>
  </metainfo>
  <div id="app">
    <router-view />
  </div>
</template>

<script lang="ts">
import {defineComponent, watch} from "vue";
import {useRoute} from "vue-router";
import {useRootStore} from "@/application/store/useRootStore";

export default defineComponent({

  setup() {
    const route = useRoute();
    const rootStore = useRootStore()

    watch(() => route.path, (newPath) => {
      rootStore.setCurrentRoute(newPath)
    });

    return {}
  }
});
</script>
