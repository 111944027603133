import { Router } from 'vue-router'

interface Middleware {
  name: string
  params: any
}

const registerMiddlewares = (router: Router): void => {
  router.beforeEach(async (to, from, next) => {
    if (to.matched.some((record) => record.meta.middleware)) {
      const middlewares = _parseMiddlewares(to.meta.middleware as string)
      let fail = false
      for (let index = 0; index < middlewares.length; index++) {
        const middleware = middlewares[index]
        const file = await import('./middlewares/' + middleware.name)
        const pass = await file.handle(to, from, next, ...middleware.params)
        if (!pass) {
          fail = true
        }
      }

      if (fail) {
        next({
          path: '/login'
        })
      }
    } else {
      // check if path exists
      if (to.matched.length === 0) {
        next({
          path: '/'
        })
      } else {
        next()
      }
    }
  })
}

const _parseMiddlewares = (middleware: string): Array<Middleware> => {
  const middlewares = middleware.split('|') as Array<any>
  const finalList: Array<Middleware> = []
  middlewares.map((mid) => {
    finalList.push({
      name: mid.split(':')[0],
      params: mid.split(':')[1] ? mid.split(':')[1].split(',') : ([] as Array<Middleware>)
    })
  })
  return finalList
}


export { registerMiddlewares }
