import CryptoJS from 'crypto-js'

const get = (key: string): string | null => {
    const encryptedData = localStorage.getItem(key)
    if (encryptedData == null) {
        return null
    }
    const decrypted = CryptoJS.AES.decrypt(encryptedData, process.env.VUE_APP_CRYPTO_KEY)
    return decrypted.toString(CryptoJS.enc.Utf8)
}

const set = (key: string, value: string): void => {
    const dataEncrypted = CryptoJS.AES.encrypt(value, process.env.VUE_APP_CRYPTO_KEY)
    localStorage.setItem(key, dataEncrypted)
}

const setSession = (key: string, value: string): void => {
    const dataEncrypted = CryptoJS.AES.encrypt(value, process.env.VUE_APP_CRYPTO_KEY)
    sessionStorage.setItem(key, dataEncrypted)
}

const getSession = (key: string): string | null => {
    const encryptedData = sessionStorage.getItem(key)
    if (encryptedData == null) {
        return null
    }
    const decrypted = CryptoJS.AES.decrypt(encryptedData, process.env.VUE_APP_CRYPTO_KEY)
    return decrypted.toString(CryptoJS.enc.Utf8)
}

const remove = (key: string): void => {
    localStorage.removeItem(key)
}

const clear = (): void => {
    localStorage.clear()
    sessionStorage.clear()

    //TODO: Refactorizar y quitar de aqui, meter en un middleware o algo de que cuando tire de esto si no existe que ponga uno por defecto
    storage.set('currentLocale', 'es')
}

export const storage = {
    get,
    set,
    clear,
    remove,
    setSession,
    getSession
}
