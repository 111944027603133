export default class Pagination {
  current_page: number
  first_page_url: string
  from: number | null
  last_page: number
  last_page_url: string
  next_page_url: string | null
  per_page: number
  prev_page_url: string | null
  to: number | null
  total: number

  constructor(
    rawData = {
      current_page: 1,
      first_page_url: '',
      from: 1,
      last_page: 1,
      last_page_url: '',
      next_page_url: '',
      per_page: 10,
      prev_page_url: '',
      to: null,
      total: 0
    }
  ) {
    this.current_page = rawData.current_page
    this.first_page_url = rawData.first_page_url
    this.from = rawData.from
    this.last_page = rawData.last_page
    this.last_page_url = rawData.last_page_url
    this.next_page_url = rawData.next_page_url
    this.per_page = rawData.per_page ?? 15
    this.prev_page_url = rawData.prev_page_url
    this.to = rawData.to
    this.total = rawData.total
  }

  get finished() {
    return this.total === this.to
  }
}
