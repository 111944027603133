import { http } from '@/infrastructure/http/http'
import { storage } from '@/infrastructure/storage/storage'
import { authService } from './auth.service'
const baseHeaders = {

}

const host = process.env.VUE_APP_API_ENDPOINT
const port = process.env.VUE_APP_API_ENDPOINT_PORT

const parseUrl = (url: string) => {
  const baseURL = port ? `${host}:${port}/api/` : `${host}/api/`
  return baseURL + url
}

const privatePut = async (url: string, body: any, headers: Record<string, any> = {}) => {
  const finalUrl = parseUrl(url)
  const finalHeaders = {
    ...baseHeaders,
    ...headers,
    Authorization: authService.getToken()
  }
  return await http.put(finalUrl, body, finalHeaders)
}

const publicPost = async (url: string, body: any, headers: Record<string, any> = {}) => {
  const finalUrl = parseUrl(url)
  const finalHeaders = {
    ...baseHeaders,
    ...headers
  }
  return await http.post(finalUrl, body, finalHeaders)
}

const privatePost = async (url: string, body: any, headers: Record<string, any> = {}) => {
  const finalUrl = parseUrl(url)
  const finalHeaders = {
    ...baseHeaders,
    ...headers,
    Authorization: authService.getToken()
  }
  return await http.post(finalUrl, body, finalHeaders)
}

const publicGet = async (url: string, headers: Record<string, any> = {}) => {
  const finalUrl = parseUrl(url)
  const finalHeaders = {
    ...baseHeaders,
    ...headers
  }
  return await http.get(finalUrl, finalHeaders)
}

const privateGet = async (url: string, headers: Record<string, any> = {}) => {
  const finalUrl = parseUrl(url)
  const finalHeaders = {
    ...baseHeaders,
    ...headers,
    Authorization: authService.getToken()
  }
  return await http.get(finalUrl, finalHeaders)
}

const destroy = async (url: string, body?: any, headers: Record<string, any> = {}) => {
  const finalUrl = parseUrl(url)
  const finalHeaders = {
    ...baseHeaders,
    ...headers,
    Authorization: authService.getToken()
  }
  return await http.delete(finalUrl, body, finalHeaders)
}


const privatePostWithFile = async (url: string, body: FormData, headers: Record<string, any> = {}) => {
  const finalUrl = parseUrl(url)
  const finalHeaders = {
    ...baseHeaders,
    ...headers,
    Authorization: authService.getToken()
  }
  return await http.postWithFile(finalUrl, body, finalHeaders)
}

export const apiService = {
  publicPost,
  privatePost,
  publicGet,
  privateGet,
  privatePut,
  privatePostWithFile,
  destroy
}
